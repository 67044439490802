@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  line-height: 1.42em;
  color:#d4bfa4;
  background-color:#262a35;
  margin: 20px;
  height: 100%;
}

h1 {
  font-size:3em;
  font-weight: 300;
  line-height:1em;
  text-align: center;
  color: #4DC3FA;
}

.container th h1 {
  font-weight: bold;
  font-size: 1em;
  color: #9ee5f5;
}

.container td {
  font-weight: normal;
  font-size: 1em;
  -webkit-box-shadow: 0 2px 2px -2px #0E1119;
  -moz-box-shadow: 0 2px 2px -2px #0E1119;
  box-shadow: 0 2px 2px -2px #0E1119;
}

.container {
  text-align: left;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
  display: table;
  padding: 0 0 8em 0;
  max-width: 80vmin;
}

.container td, .container th {
  padding-bottom: 2%;
  padding-top: 2%;
  padding-left:2%;
}

/* Background-color of the odd rows */
.container tr:nth-child(odd) {
  background-color: #383d48;
}

/* Background-color of the even rows */
.container tr:nth-child(even) {
  background-color: #323640;
}

.container th {
  background-color: #262a35;
  font-size: 130%;
  padding-left: 0%;
}

.container td:first-child {
  color: #ff828c;
  font-size: 120%;
}

.container tr:hover {
  background-color: #464A52;
  -webkit-box-shadow: 0 6px 6px -6px #0E1119;
  -moz-box-shadow: 0 6px 6px -6px #0E1119;
  box-shadow: 0 6px 6px -6px #0E1119;
}

.world-number-header h1 {
  text-align: left;
  padding-left: 10%;
}

.hits-header h1 {
  text-align: center;
}

.stream-order-header h1 {
  text-align: right;
  padding-right: 10%;
}

.gggbbb-class {
  color: rgba(100, 255, 100, 0.85);
}

.bbbggg-class {
  color: #6985ff;
}

.navbar {
  display: flex;
  flex-direction: column;
}

.git-icon {
  position: relative;
  top: 0.5rem;
  right: 0.8rem;
  height: 50%;
  width: auto;
}

.cloud-icon {
  position: relative;
  right: 0.8rem;
}

.arrow {
  position: relative;
  left: 1.5rem;
}
